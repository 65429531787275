<template>
  <KTCard :page="page">
    <template #body>
      <p class="mb-2">
        <span class="font-weight-bold">Produto:</span> {{ produto.descricao }}
      </p>
      <p class="mb-6">
        <span class="font-weight-bold">Unidade de Medida:</span>
        {{ produto.nome_unidade_medida }}
      </p>

      <div class="accordion" role="tablist" v-if="receita.length">
        <b-card
          no-body
          class="mb-1"
          v-for="(item, index) in receita"
          :key="'ano-' + item.ano"
        >
          <b-card-header
            header-tag="header"
            class="p-3 d-flex justify-content-between align-items-center"
            role="tab"
            :style="{ cursor: 'auto' }"
          >
            <b>Dados do Ano {{ item.ano }}:</b>
            <b-button
              v-b-toggle="'accordion-' + item.ano"
              variant="secondary"
              size="sm"
            >
              <span v-if="!accordion[index]">
                <i class="fa fa-angle-down"></i> Abrir
              </span>
              <span v-else> <i class="fa fa-angle-up"></i> Fechar </span>
            </b-button>
          </b-card-header>
          <b-collapse
            :id="'accordion-' + item.ano"
            accordion="my-accordion"
            role="tabpanel"
            v-model="accordion[index]"
          >
            <b-card-body>
              <p class="mb-2">
                <span class="font-weight-bold font-size-lg">
                  Quantidade total ({{ produto.nome_unidade_medida }}):
                </span>
                {{ item.quantidade_vendas_total }}
              </p>
              <p class="mb-0">
                <span class="font-weight-bold font-size-lg">
                  Valor total ({{ produto.simbolo_moeda }}):
                </span>
                {{ item.valor_vendas_total }}
              </p>
              <b-row>
                <b-col
                  sm="6"
                  md="4"
                  lg="3"
                  v-for="mes in 12"
                  :key="'col-' + mes"
                >
                  <b-card no-body class="p-3 mt-8">
                    <p class="font-weight-bold">Mês {{ mes }}</p>
                    <b-form-group
                      label="Quantidade"
                      label-size="lg"
                      class="mb-4"
                    >
                      <KTInputNumeric
                        :value="item['quantidade_vendas_mes_' + mes]"
                        :precision="2"
                        autocomplete="off"
                        disabled
                      />
                    </b-form-group>
                    <b-form-group label="Valor" label-size="lg" class="mb-0">
                      <b-input-group :prepend="produto.simbolo_moeda">
                        <KTInputNumeric
                          :value="item['valor_vendas_mes_' + mes]"
                          :precision="2"
                          autocomplete="off"
                          disabled
                        />
                      </b-input-group>
                    </b-form-group>
                  </b-card>
                </b-col>
              </b-row>
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
    </template>
  </KTCard>
</template>

<script>
import ApiMixin from "@/core/mixins/api.mixin.js";
import HelpersMixin from "@/core/mixins/helpers.mixin.js";

export default {
  name: "PipReceitaForm",
  props: {
    idplano_investimento: {
      required: true
    },
    idpip_produto: {
      default: null
    }
  },
  mixins: [ApiMixin, HelpersMixin],
  data() {
    return {
      page: {
        icon: "la la-thumbs-up",
        title: "Receitas",
        description: `Aqui não é necessário preencher nenhuma informação,
          mas aproveite para conferir se os valores estão consistentes.
          Caso seja necessário corrigir algum lançamento, retorne para
          Plano de Vendas ou Preço de Venda.`
      },
      receita: [],
      produto: {},
      anos: 0,
      accordion: [true],
      tipo_plano_investimento: null
    };
  },
  computed: {
    apiResource() {
      return `pip/${this.idplano_investimento}/receita`;
    },
    manual() {
      return {
        session: "receita",
        tipo: this.tipo_plano_investimento
      };
    },
    parentRoute() {
      return {
        name: "pip_receita_list",
        params: { idplano_investimento: this.idplano_investimento }
      };
    }
  },
  methods: {
    getPageData() {
      const requests = [this.Api.GetData(this.idpip_produto)];

      const resolveFuntion = (res) => {
        // Requests index's
        const R_GETDATA = 0;

        this.receita = res[R_GETDATA].receita ?? [];
        this.anos = res[R_GETDATA].anos;
        this.produto = res[R_GETDATA].produto ?? {};
        this.tipo_plano_investimento = res[R_GETDATA].tipo_plano_investimento;

        this.initManual(this.manual.session, this.manual.tipo);
      };

      this.ApiGetPageData(requests, resolveFuntion);
    }
  },
  mounted() {
    this.getPageData();
  }
};
</script>
